
import { Component, Vue } from 'vue-property-decorator'
import { dayFormat } from '../../utils/formatDate'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import { SumecDetail, SumecDetailObj, Location, SumecTime, CuttimeList } from '../../types/sumec.d'

@Component
export default class DeviceDetail extends Vue {
  private deviceId = ''
  private date: string[] = []
  private sumecInfo: SumecDetail | { chargeLongitude: string; chargeLatitude: string; deviceNumber: string } = {
    chargeLongitude: '',
    chargeLatitude: '',
    deviceNumber: ''
  }

  private value1 = [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)]
  private sumecSail = {}
  private sumecStatistics = {}
  private areaLocationList = []
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []
  private baseImg = require('@/assets/icon/sumec/base.svg')
  private tableData: SumecDetail[] = []
  private loading = false

  private childLoading = false
  private childTableData: SumecDetailObj[] = []
  private expands: any[] = []
  private childPage = 1
  private childSize = 50
  private childTotal = 0
  private getMore = false
  private rowData: SumecDetail | null = null
  private rowMowingId = ''

  private active = ''
  private controlData: SumecTime = {
    cuttpNum: '',
    deviceId: '',
    cuttp: '',
    rain: '',
    led: '',
    cuttimeList: []
  }

  private dialogVisible1 = false
  private taskData: CuttimeList[] = []
  private taskInfoData: CuttimeList[] = []
  private taskLoading = false
  private isCheck = false
  private isOrder = true // 是否可下发指令

  private workLoading = false
  private dialogVisible2 = false
  //
  private areaList = []
  mapName = ''

  page = 1
  size = 10
  total = 0
  getRowKeys (row: {mowingId: string}) {
    return row.mowingId
  }

  get isShowArea () {
    return this.areaList.length > 0
  }

  created () {
    this.deviceId = this.$route.params.deviceId || ''
    this.active = this.$route.params.active || 'DeviceInfo'
    this.defaultDate()
    this.getData()
    this.getSumecDetail()
  }

  destroyed (): void {
    if (this.map) {
      this.map.clearMap()
    }
    window.removeEventListener('scroll', this.tableScroll)
  }

  defaultDate () {
    // 默认最近一个月
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
    this.date = [dayFormat(start.getTime()), dayFormat(end.getTime())]
    this.getTableData()
  }

  getData () {
    this.$axios.get(this.$apis.sumec.selectSumecDeviceDetail, {
      deviceId: this.deviceId
    }).then((res) => {
      this.sumecInfo = res.sumecInfo || {}
      this.sumecSail = res.sumecSail || {}
      this.getAreaList()
      this.sumecStatistics = res.sumecStatistics || {}
      if (res.areaLocationList && res.areaLocationList.length > 0) {
        this.areaLocationList = res.areaLocationList || []
      }
    })
  }

  // 获取割草机配置详情
  getSumecDetail () {
    this.workLoading = true
    this.$axios.get(this.$apis.sumec.selectSumecConfigByDeviceId, {
      deviceId: this.deviceId
    }).then((res) => {
      this.controlData = res || []
      this.handleData(res)
    }).finally(() => {
      this.workLoading = false
    })
  }

  // 获取区域
  getAreaList () {
    this.$axios.get(this.$apis.sumec.selectSumecConfigMapByList, {
      deviceNumber: this.sumecInfo.deviceNumber
    }).then((res) => {
      this.areaList = res || []
    })
  }

  // 处理详情表格数据
  handleData (res: any) {
    const weekData = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
    res.cuttimeList.forEach((item: any, index: number) => {
      item.mapName = item.mapName && item.mapName !== 'null' ? item.mapName : ''
      item.week = weekData[index]
      if (item.time2Start === '00:00' && item.time2End === '00:00') {
        item.timeNumber = '1'
        item.time2Start = null
        item.time2End = null
        if (item.time1Start === '00:00' && item.time1End === '00:00') {
          item.timeNumber = '1'
          item.time1Start = null
          item.time1End = null
        }
      } else {
        item.timeNumber = '2'
      }
    })
    this.taskData = JSON.parse(JSON.stringify(res.cuttimeList))
    this.taskInfoData = res.cuttimeList
    for (let i = this.taskInfoData.length - 1; i >= 0; i--) {
      if (this.taskInfoData[i].isWork === '2') {
        this.taskInfoData.splice(i, 1)
      }
    }
  }

  // 获取表格数据
  getTableData () {
    this.loading = true
    const info = {
      startTime: this.date[0],
      endTime: this.date[1],
      deviceId: this.deviceId
    }
    this.$axios.get(this.$apis.sumec.selectSumecMowingPage, {
      ...info,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 作业区域弹窗
  getWorkArea () {
    this.dialogVisible2 = true
    this.getProjectLocation()
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.$route.params.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, list || [], null)

      // 基站添加
      if (this.sumecInfo.chargeLongitude && this.sumecInfo.chargeLatitude) {
        const markerBase: any = new AMap.Marker({
          icon: new AMap.Icon({
            // 图标的取图地址
            image: this.baseImg
          }),
          clickable: false,
          offset: new AMap.Pixel(-19, -18),
          position: new AMap.LngLat(Number(this.sumecInfo.chargeLongitude), Number(this.sumecInfo.chargeLatitude))
        })
        this.map && this.map.add(markerBase)
      }

      // 割草区域围栏添加
      const areaList: Array<{ longitude: number; latitude: number; fenceCode: string; name: string }> = this.areaLocationList.map((areaItem: Location) => {
        return {
          name: areaItem.mapName || '',
          fenceCode: areaItem.fenceCode || '',
          longitude: Number(areaItem.locationLongitude),
          latitude: Number(areaItem.locationLatitude)
        }
      })
      drawPolygon((this.map as AMap.Map), areaList, {
        fillColor: 'rgb(69, 182, 172)', // 背景色
        fillOpacity: 0.7, // 背景透明
        strokeWeight: 2, // 线条宽度
        strokeColor: 'rgba(69, 182, 172, 1)', // 线条色
        strokeStyle: 'dashed', // 线条轮廓线样式
        clickable: false // 是否可点击
      })
    })
  }

  onSearch () {
    this.page = 1
    this.getTableData()
  }

  getChildData (row: SumecDetail) {
    this.childLoading = true
    this.$axios.get<{ total: number; list: SumecDetailObj[] }>(
      this.$apis.sumec.selectSumecMowingPage,
      {
        deviceId: this.deviceId,
        page: this.childPage,
        size: this.childSize,
        topGrass: this.rowMowingId
      }
    )
      .then((res) => {
        this.childTotal = res.total || 0

        this.childTableData =
          this.childPage === 1
            ? res.list || []
            : this.childTableData.concat(res.list || [])
        this.getMore = this.childTableData.length < res.total
        this.tableData.forEach((temp: SumecDetail, index) => {
          // 点击的行，
          if (row.rowMowingId === temp.rowMowingId) {
            this.tableData[index].childTableData = this.childTableData
          }
        })
      })
      .finally(() => {
        this.childLoading = false
      })
    this.$nextTick(() => {
      ;(this.$refs.tableWrap as any).bodyWrapper.addEventListener(
        'scroll',
        this.tableScroll
      )
    })
  }

  async expandChange (row: SumecDetail, expandedRows: SumecDetail[]) {
    this.childPage = 1
    if (expandedRows.length > 0) {
      this.expands = [row.mowingId]
      this.rowMowingId = row.mowingId
      this.rowData = row
      this.getChildData(row)
    } else {
      this.expands = []
    }
  }

  indexMethod (index: number) {
    return index + 1
  }

  tableScroll (e: any) {
    const scrollHeight = e.target.scrollHeight
    const scrollTop = e.target.scrollTop
    const clientHeight = e.target.clientHeight
    const distance = scrollHeight - scrollTop - clientHeight
    if (distance < 60 && this.getMore) {
      // 距离小于60
      this.childPage++
      this.getMore = false
      this.rowData && this.getChildData(this.rowData)
    }
  }

  // 控制台
  // 开启设备
  openDevice () {
    if (this.isOrder) {
      this.sendOrder({ motion: '1' })
    } else {
      this.$message.warning('正在下发指令，请勿重复操作')
    }
  }

  // 关闭设备
  closeDevice () {
    if (this.isOrder) {
      this.sendOrder({ motion: '2' })
    } else {
      this.$message.warning('正在下发指令，请勿重复操作')
    }
  }

  // 回充
  chargeDevice () {
    if (this.isOrder) {
      this.sendOrder({ motion: '3' })
    } else {
      this.$message.warning('正在下发指令，请勿重复操作')
    }
  }

  // led灯控
  getLed () {
    if (this.isOrder) {
      if (this.controlData.led) {
        this.controlData.led = this.controlData.led === '1' ? '3' : this.controlData.led === '2' ? '1' : '2'
      } else {
        this.controlData.led = '1'
      }
      this.sendOrder({ led: this.controlData.led })
    } else {
      this.$message.warning('正在下发指令，请勿重复操作')
    }
  }

  // 雨天作业开关
  changeRainWork () {
    if (this.isOrder) {
      this.sendOrder({ rain: this.controlData.rain })
    }
  }

  // 发送割草机指令
  sendOrder (data: any) {
    this.isOrder = false
    const info = {
      deviceId: this.deviceId,
      ...data
    }
    this.$axios.post(this.$apis.sumec.insertSumecInstructions, info).then(() => {
      this.$message.success('发送指令成功')
      this.getSumecDetail()
    }).finally(() => {
      this.isOrder = true
    })
  }

  // 同步时间
  syncTime () {
    if (this.isOrder) {
      this.isOrder = false
      this.$axios.get(this.$apis.sumec.insertSumecTime, {
        deviceId: this.deviceId
      }).then(() => {
        this.$message.success('同步时间成功')
      }).finally(() => {
        this.isOrder = true
      })
    } else {
      this.$message.warning('正在下发指令，请勿重复操作')
    }
  }

  // 切换区域
  changeArea () {
    if (this.isOrder) {
      this.isOrder = false
      this.$axios.post(this.$apis.sumec.insertSumecInstructionsMap, {
        deviceNumber: this.sumecInfo.deviceNumber,
        mapName: this.mapName
      }).then(() => {
        this.$message.success('切换区域成功!')
      }).finally(() => {
        this.isOrder = true
      })
    } else {
      this.$message.warning('正在下发指令，请勿重复操作')
    }
  }

  // 同步信息
  syncInformation () {
    if (this.isOrder) {
      this.isOrder = false
      this.$axios.get(this.$apis.sumec.insertSumecData, {
        deviceId: this.deviceId
      }).then(() => {
        this.$message.success('同步信息成功')
      }).finally(() => {
        this.isOrder = true
      })
    } else {
      this.$message.warning('正在下发指令，请勿重复操作')
    }
  }

  // 任务设置
  // 任务设置弹窗
  getTaskConfig () {
    this.dialogVisible1 = true
  }

  // 保存任务设置
  submit () {
    let cycleType = false
    this.taskData.map((item) => {
      if (item.isWork === '1') {
        cycleType = true
      }
    })
    for (let i = 0; i < this.taskData.length; i++) {
      if (this.taskData[i].isWork === '1' && ((this.taskData[i].time1End === null || this.taskData[i].time1Start === null) || ((this.taskData[i].time2End === null || this.taskData[i].time2Start === null) && this.taskData[i].timeNumber === '2'))) {
        this.$message.warning('已勾选工作日请选择时间段！')
        return
      } else if (!cycleType && this.controlData.cuttp === '1') {
        this.$message.warning('未勾选工作日无法开启周期性工作！')
        return
      } else if (this.controlData.cuttp === '1' && this.controlData.cuttpNum > '99') {
        this.$message.warning('最大割草间隔时间为99天！')
        return
      } else if (this.taskData[i].isWork === '1' && this.isShowArea && !this.taskData[i].mapName) {
        this.$message.warning('请选择作业区域')
        return
      } else {
        this.isCheck = true
      }
    }
    if (this.isCheck) {
      this.taskLoading = true
      this.taskData.forEach((item: any, index: number) => {
        delete this.taskData[index].timeNumber
        delete this.taskData[index].week
      })
      const info = {
        deviceId: this.deviceId,
        cuttp: this.controlData.cuttp,
        cuttpNum: this.controlData.cuttp === '1' ? String(this.controlData.cuttpNum) : '1',
        cuttimeList: this.taskData
      }
      this.$axios.post(this.$apis.sumec.insertSumecCuttime, info).then(() => {
        this.$message.success('配置成功！')
        this.dialogVisible1 = false
      }).finally(() => {
        this.getSumecDetail()
        this.taskLoading = false
      })
    }
  }

  // 添加第二时间段
  onAdd (row: any) {
    row.timeNumber = '2'
  }

  // 删除第二时间段
  onDelete (row: any) {
    row.timeNumber = '1'
    row.time2End = null
    row.time2Start = null
  }

  // 取消设置
  closeSubmit () {
    this.taskLoading = false
    this.dialogVisible1 = false
    this.getSumecDetail()
  }
}
